<template>
    <section class="sidebar-categories-two">
        <div class="content-head">
            <h3 class="heading">
                {{ title }}
            </h3>
            <NuxtLink no-prefetch v-if="path" :to="path" rel="follow">Ver más</NuxtLink>
        </div>

        <ul v-if="info.length > 0" class="sidebar-categories-two__list">
            <li v-for="(item, index) in info" :key="index">
                <NuxtLink no-prefetch :to="`${path}/${item.slug}`" rel="follow">
                    <picture>
                        <img
                            v-if="item.image"
                            :src="item.image.url"
                            :title="item.name"
                            :alt="item.name"
                            :onerror="`this.onerror=null;this.src='${
                                defaultImage[path === '/' + route ? 'store' : 'category']
                            }'`"
                    /></picture>
                    {{ item.name }}
                </NuxtLink>
            </li>
        </ul>
        <p v-else class="no-similar">No hay categorías similares disponibles</p>
    </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { Models } from '~/types/models'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'

export default defineComponent({
    name: 'SidebarCategoriesTwo',
    props: {
        path: {
            type: String,
            required: true,
        },
        info: {
            type: Array as PropType<Models.Category[]>,
            required: true,
        },
        title: {
            type: String,
        },
        model: {
            type: String as PropType<'secondary'>,
            default: 'primary',
        },
        border: {
            type: Boolean,
            default: true,
        },
        iconColor: {
            type: String as PropType<'primary'>,
            default: 'primary',
        },
        iconName: {
            type: String as PropType<'shop'>,
            default: 'shop',
        },
    },
    data() {
        const { $lang } = useNuxtApp()
        const { defaultImages } = useVariantsDictionary()
        return {
            route: $lang.routes.brands,
            defaultImage: defaultImages,
        }
    },
    computed: {
        icon(): object {
            // @ts-ignore
            return this.$assets[this.iconColor][this.iconName]
        },
    },
})
</script>

<style lang="postcss" scoped>
.sidebar-categories-two {
    @apply rounded-2xl border border-opacity-5 bg-white p-3;

    .content-head {
        @apply mb-2 flex justify-between border-white;
        .heading {
            @apply text-lg font-thin;
        }
        a {
            @apply self-center text-sm font-medium text-site-primary hover:underline;
        }
    }

    &__list {
        @apply flex flex-wrap;
        li {
            @apply m-1 block;
            a {
                @apply flex items-center whitespace-nowrap rounded-md border border-gray-200 bg-gray-100 px-1.5 py-1 font-medium hover:bg-gray-200;
                picture {
                    @apply mr-0.5 block h-8 w-8;
                    img {
                        @apply h-full w-full object-contain;
                    }
                }
            }
        }
    }
    .no-similar {
        @apply py-4 text-center text-site-gray;
    }
}
</style>
